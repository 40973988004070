var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CCardHeader", [_c("span", [_c("strong", [_vm._v("Sorular")])])]),
      _c("Repeater", {
        attrs: { title: "Anket Soruları", slug: "SURVEY_QUESTIONS" },
      }),
      _c("Repeater", {
        attrs: { title: "İptal Soruları", slug: "CANCEL_QUESTIONS" },
      }),
      _c("Repeater", {
        attrs: { title: "Erken İade Soruları", slug: "EARLY_RETURN_QUESTIONS" },
      }),
      _c("Repeater", {
        attrs: {
          title: "Üyelik İptal Soruları",
          slug: "USER_DELETE_QUESTIONS",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }