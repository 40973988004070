<template>
  <div>
    <CCardHeader>
      <span><strong>Sorular</strong></span>
    </CCardHeader>
    <Repeater title="Anket Soruları" slug="SURVEY_QUESTIONS" />
    <Repeater title="İptal Soruları" slug="CANCEL_QUESTIONS" />
    <Repeater title="Erken İade Soruları" slug="EARLY_RETURN_QUESTIONS" />
    <Repeater title="Üyelik İptal Soruları" slug="USER_DELETE_QUESTIONS" />
  </div>
</template>

<script>
import Repeater from "@/components/Singular/Repeater";

export default {
  name: "Questions",
  components: {
    Repeater,
  },
};
</script>
